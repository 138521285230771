'use client';

import Link from 'next/link';
import { useCallback, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import { TextInput } from '@/components/dom/form-elements';
import { Paragraph } from '@/components/dom/text-elements';
import Icon from '@/components/global/icon';
import { BuildFundamentalInputDefaults } from '@/components/index-builder/fundamental-inputs';
import { FilterSecurities } from '@/components/index-builder/helpers';
import LoadingIcon from '@/components/ui/loading-icon';
import { getCompanyPageUrls } from '@/helpers/company';
import useOutsideClick from '@/helpers/hooks/useOutsideClick';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { InstrumentSearchResultType } from '@/types/index';
import { type AllTrackingPropertiesTypes } from '@/types/tracking';

const MAX_RESULTS = 10;
const RunFilterSecurities = async (searchValue: string) => {
    const response = FilterSecurities(
        true,
        false,
        false,
        false /* recommendSimilarInIndexBuilder */,
        [],
        [],
        [],
        [],
        BuildFundamentalInputDefaults(),
        searchValue,
        MAX_RESULTS
    );

    return response.then((data) => {
        const instruments = data.instruments;

        // Sort by market cap descending
        // TODO: should this be done by the server? especially if we're doing pagination
        return instruments.sort((a: InstrumentSearchResultType, b: InstrumentSearchResultType) =>
            Number(a.latestFundamentals?.marketCapitalizationUsd) <
            Number(b.latestFundamentals?.marketCapitalizationUsd)
                ? 1
                : -1
        );
    });
};

interface CompanySearchFieldProps {
    className?: string;
    fieldClassName?: string;
    trackingProperties?: Omit<AllTrackingPropertiesTypes, 'component' | 'category'>;
}

interface SearchResultsProps {
    className?: string;
    results: Array<InstrumentSearchResultType>;
    searchValueEntered?: boolean;
    isSearching?: boolean;
}

const SearchResults = ({ className, results, searchValueEntered = false, isSearching = false }: SearchResultsProps) => {
    return (
        <ul
            className={cn(
                'list-none absolute top-full left-0 translate-y-4 w-full z-10 bg-white border border-analyst-lavender rounded-lg shadow-md overflow-hidden',
                className
            )}
        >
            {results.length < 1 && searchValueEntered && (
                <div className="py-3">
                    <Paragraph className="mb-0 font-brand-md text-lg text-thematic-purple text-center p-4">
                        {isSearching ? 'Searching...' : 'No results found'}
                    </Paragraph>

                    {isSearching && (
                        <div className="flex items-center justify-center flex-grow">
                            <LoadingIcon />
                        </div>
                    )}
                </div>
            )}

            {results.map((instrument) => {
                const href = getCompanyPageUrls({ exchange: instrument.exchange, ticker: instrument.symbol });
                return (
                    <li
                        key={instrument.id}
                        className="bg-white border-b border-brand-gray last-child:border-b-0 p-3"
                    >
                        <Link
                            href={href}
                            title={`${instrument.companyName} (${instrument.symbol}) - Company Page`}
                            className="text-thematic-purple font-brand-md no-underline hover:text-analyst-dark-lavender visited:text-thematic-light-blue transition-colors"
                        >
                            {instrument.symbol} - {instrument.companyName}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

export const CompanySearchField = ({
    className,
    fieldClassName,
    trackingProperties = {} as Omit<AllTrackingPropertiesTypes, 'component'>,
}: CompanySearchFieldProps) => {
    const { categories, components, eventTypes, trackManualEvent } = usePosthogTracking();
    const searchFieldRef = useRef<HTMLDivElement>(null);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [instruments, setInstruments] = useState<Array<InstrumentSearchResultType>>([]);
    const [searchValue, setSearchValue] = useState('');
    const [showResults, setShowResults] = useState(false);
    const clearSearch = () => setSearchValue('');
    const searchQueryEntered = searchValue.trim().length > 0;
    const showResultsList = useCallback(() => {
        setShowResults(true);
    }, [setShowResults]);
    const hideResultsList = () => setShowResults(false);
    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setIsSearching(true);
            setSearchValue(value);
        },
        [setSearchValue]
    );
    const onSearch = useCallback(
        async (searchVal: string) => {
            const searchResults = await RunFilterSecurities(searchVal);

            trackManualEvent({
                eventType: eventTypes.COMPANY_SEARCH,
                trackingProperties: {
                    ...trackingProperties,
                    category: categories.COMPANY_SEARCH,
                    component: components.COMPANY_SEARCH_FIELD,
                    resultsCount: searchResults.length,
                    searchQuery: searchVal,
                },
            });
            setInstruments(searchResults);
            setIsSearching(false);
        },
        [
            categories.COMPANY_SEARCH,
            components.COMPANY_SEARCH_FIELD,
            eventTypes.COMPANY_SEARCH,
            trackManualEvent,
            trackingProperties,
        ]
    );
    useDebounce(
        () => {
            onSearch(searchValue);
        },
        400,
        [searchValue]
    );

    useOutsideClick(searchFieldRef, hideResultsList);

    return (
        <div
            ref={searchFieldRef}
            className={cn('relative', className)}
        >
            <Icon
                type="search"
                size="lg"
                color="analystPurple"
                className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10"
            />

            <TextInput
                placeholder="Search for any company or ticker symbol"
                value={searchValue}
                isClearable={true}
                onFocus={showResultsList}
                onClear={clearSearch}
                fieldClassName={cn(
                    'py-3 border-analyst-lavender focus:border-analyst-purple hover:border-analyst-lavender text-md rounded-xl placeholder:text-analyst-gray-lighter block pr-12 pl-12 sm:pl-12',
                    fieldClassName
                )}
                onChange={onChange}
            />
            {showResults && searchQueryEntered && (
                <SearchResults
                    isSearching={isSearching}
                    results={instruments}
                    searchValueEntered={searchValue.trim().length > 0}
                />
            )}
        </div>
    );
};
