import { format } from 'date-fns';
import { gql } from 'graphql-request';

import { coreFiltersToAnalystFilterInput } from '@/components/analyst/core-filters-read-only';
import { OptionsType } from '@/components/dom/form-elements';
import { FundamentalField, FundamentalModifier, FundamentalsType } from '@/components/index-builder/fundamental-inputs';
import { FormatUtcDate } from '@/helpers/dates';
import { AbortSignal, GQL_CLIENT } from '@/lib/graphql';
import {
    AnalysisFiguresType,
    asBasicStrategy,
    InstrumentSearchResultType,
    ProcessedIndexItem,
    StrategyType,
    WeightingStrategyType,
} from '@/types/index';
import { WeightStrategyInput } from '@/types/instrument';

const multiplierFromScale = (scale: string | number | undefined) => {
    if (scale === 'M') {
        return 1000000;
    } else if (scale === 'B') {
        return 1000000000;
    } else if (scale === 'T') {
        return 1000000000000;
    }
    return 1;
};

export const FilterSecurities = async (
    companyEnabled: boolean,
    cryptoEnabled: boolean,
    etfEnabled: boolean,
    recommendSimilarInIndexBuilder: boolean,
    sectors: Array<string>,
    industries: Array<string>,
    regions: Array<string>,
    countries: Array<string>,
    definedFundamentals: FundamentalsType,
    searchKeyword: string,
    limit?: number
): Promise<{ instruments: Array<InstrumentSearchResultType> }> => {
    const fundamentals: Array<object> = [];
    let keywordFilter: object | undefined = undefined;

    // build the fundamentals section
    for (const fieldKey in definedFundamentals) {
        const field = definedFundamentals[fieldKey as FundamentalField];
        for (const key in field) {
            const compareKey = key as FundamentalModifier;
            const value = field[compareKey];
            if ([FundamentalModifier.GT, FundamentalModifier.LT].indexOf(compareKey) !== -1 && value) {
                const multiplier =
                    compareKey === FundamentalModifier.GT
                        ? multiplierFromScale(field[FundamentalModifier.gtScale])
                        : multiplierFromScale(field[FundamentalModifier.ltScale]);
                if (fieldKey && compareKey && value) {
                    fundamentals.push({
                        comparison: {
                            comparator: compareKey,
                            field: fieldKey,
                            value: (value as number) * multiplier,
                        },
                    });
                }
            }
        }
    }

    const FULL_MATCH_KEYWORD_QUERY_FIELDS = ['SYMBOL', 'BUSINESS_DESCRIPTION', 'INDUSTRY_NAME', 'SECTOR_NAME', 'CEO'];
    const PARTIAL_MATCH_KEYWORD_QUERY_FIELDS = ['COMPANY_NAME'];

    if (searchKeyword.trim()) {
        // Split search into terms by commas. TODO: add a UI hint to this behavior, and/or choose a more obvious separator
        const searchKeywordTerms = searchKeyword.split(',').map((x) => x.trim());
        keywordFilter = {
            or: {
                filters: searchKeywordTerms.map((term) => {
                    return {
                        or: {
                            filters: [
                                {
                                    combinedQuery: {
                                        fields: FULL_MATCH_KEYWORD_QUERY_FIELDS,
                                        query: term.trim(),
                                    },
                                },
                                {
                                    combinedQuery: {
                                        elasticsearchType: 'PHRASE_PREFIX',
                                        fields: PARTIAL_MATCH_KEYWORD_QUERY_FIELDS,
                                        query: term.trim(),
                                    },
                                },
                            ],
                        },
                    };
                }),
            },
        };
    }

    const BIG_COUNTRIES = ['US', 'CN', 'JP', 'KR']; // Have 2k-6k instruments each
    if (
        !sectors.length &&
        !industries.length &&
        // We have so many instruments for US/CN/JP/KR that we don't want to show them all if they are the only filter
        (!countries.length || countries.some((c) => BIG_COUNTRIES.includes(c))) &&
        !fundamentals.length &&
        !keywordFilter
    ) {
        return { instruments: [] };
    }

    const filters = [];

    if (keywordFilter) {
        filters.push(keywordFilter);
    }

    if (sectors.length) {
        filters.push({
            in: { field: 'SECTOR_NAME', values: sectors },
        });
    }

    if (industries.length) {
        filters.push({
            in: { field: 'INDUSTRY_NAME', values: industries },
        });
    }

    if (countries.length) {
        filters.push({
            in: { field: 'PRIMARY_REGION_COUNTRY_CODE', values: countries },
        });
    }

    const instrumentTypes = [];
    if (companyEnabled) {
        instrumentTypes.push('COMPANY_STOCK');
    }
    if (cryptoEnabled) {
        instrumentTypes.push('CRYPTO');
    }
    if (etfEnabled) {
        instrumentTypes.push('ETF');
    }
    filters.push({
        in: { field: 'INSTRUMENT_TYPE', values: instrumentTypes },
    });

    if (fundamentals.length) {
        filters.push(...fundamentals);
    }

    const variables = { filter: { and: { filters } }, limit };

    const similarInstrumentsFragment = gql`
        fragment similarInstrumentsFragment on Instrument {
            similarInstruments {
                symbol
                id
                companyName
                instrumentType
                exchange
                latestFundamentals {
                    businessDescription
                    grossIncomeMargin
                    marketCapitalizationUsd
                    oneYearAnnualRevenueGrowthRate
                    enterpriseValueRevenueRatio
                    netRevenueRetention
                    ebitdaMargin
                }
            }
        }
    `;

    const query = gql`
        query FilterSecurities($filter: InstrumentSearchFilterInput, $limit: Int) {
            instrumentSearch(input: { filter: $filter, limit: $limit }) {
                instruments {
                    symbol
                    id
                    companyName
                    instrumentType
                    exchange
                    latestFundamentals {
                        businessDescription
                        grossIncomeMargin
                        marketCapitalizationUsd
                        oneYearAnnualRevenueGrowthRate
                        enterpriseValueRevenueRatio
                        netRevenueRetention
                        ebitdaMargin
                    }
                    ${recommendSimilarInIndexBuilder ? '...similarInstrumentsFragment' : ''}
                }
            }
        }
        ${recommendSimilarInIndexBuilder ? similarInstrumentsFragment : ''}
    `;

    const response: { instrumentSearch: { instruments: Array<InstrumentSearchResultType> } } = await GQL_CLIENT.request(
        query,
        variables
    );
    return response.instrumentSearch;
};

type IndustriesType = {
    sector: string;
    industries: Array<OptionsType>;
};

type SectorsAndIndustriesType = {
    name: string;
    industries: Array<{ name: string }>;
};

export const GetSectorsAndIndustries = async () => {
    // get the list of sectors and industries from the api
    const query = gql`
        query sectorsAndIndustries {
            sectorsAndIndustries {
                name
                industries {
                    name
                }
            }
        }
    `;

    const possibleSectors: Array<OptionsType> = [];
    const possibleIndustries: Array<IndustriesType> = [];
    const {
        sectorsAndIndustries,
    }: {
        sectorsAndIndustries: Array<{
            name: string;
            industries: Array<{
                name: string;
            }>;
        }>;
    } = await GQL_CLIENT.request(query);

    // pull out just the sectors to use for the drop down
    sectorsAndIndustries.forEach((item: SectorsAndIndustriesType) => {
        if (item.industries.length) {
            possibleSectors.push({ label: item.name, value: item.name });

            const tempIndustries: Array<OptionsType> = [];
            item.industries.forEach((item) => {
                tempIndustries.push({ label: item.name, value: item.name });
            });
            tempIndustries.sort((a, b) => (a.label < b.label ? -1 : 1));

            possibleIndustries.push({
                industries: tempIndustries,
                sector: item.name,
            });
        }

        possibleSectors.sort((a, b) => (a.label < b.label ? -1 : 1));
    });

    return {
        industries: possibleIndustries,
        sectors: possibleSectors,
    };
};

// TODO: handle Rules Based
export const BuildWeightStrategyInput = (strategy: StrategyType): WeightStrategyInput => {
    switch (strategy.weightingType) {
        case WeightingStrategyType.equal: {
            return { equalWeightedInstrumentIds: strategy.securityIds };
        }
        case WeightingStrategyType.marketCap: {
            return { marketCapWeightedInstrumentIds: strategy.securityIds };
        }
        case WeightingStrategyType.rootMarketCap: {
            return { rootMarketCapWeightedInstrumentIds: strategy.securityIds };
        }
        case WeightingStrategyType.price: {
            return { priceWeightedInstrumentIds: strategy.securityIds };
        }
        case WeightingStrategyType.custom: {
            return {
                customWeightedInstruments: strategy.securityIds.map((instrumentId) => {
                    return {
                        instrumentId,
                        weight: (strategy.weightValues[instrumentId] || 1).toString(),
                    };
                }),
            };
        }
        case WeightingStrategyType.rulesBasedEqualWeighted: {
            return {
                ruleBasedEqualWeightedStrategyFilter: { filter: coreFiltersToAnalystFilterInput(strategy.coreFilters) },
            };
        }
    }
    throw new Error(`Unknown weighting type on strategy ${strategy as never}`);
};

type BacktestPointType = {
    asOfDate: string;
    value: string;
};

type ComparisonResult = {
    points: Array<BacktestPointType>;
    analysis: Optional<{
        sharpeRatio: Optional<string>;
        annualRollingVolatility: string;
        stdDev: string;
        drawdown: string;
        priceToEarnings: string;
        priceToSales: string;
        priceToBook: string;
    }>;
} & (
    | {
          __typename: 'PublicIndexResult';
          publicIndex: {
              id: string;
              name: string;
              symbol: string;
          };
      }
    | {
          __typename: 'ThematicIndexResult';
          thematicIndex: {
              symbol: string;
          };
      }
    | {
          __typename: 'InstrumentResult';
          instrument: {
              symbol: string;
              exchangeName: string;
          };
      }
    | {
          __typename: 'BacktestStrategyResult';
          name: string;
          timer: number;
      }
);

export const CreateBacktest = async (
    startDate: string,
    endDate: string | null,
    benchmarkPublicIndexIds: Array<string>,
    benchmarkThematicIndexIds: Array<string>,
    benchmarkInstrumentIds: Array<string>,
    strategies: Array<StrategyType>,
    currentStrategyId: number,
    { abortController }: { abortController?: AbortController } = {}
): Promise<
    | {
          error: string;
          items?: never;
          itemSymbols?: never;
          analysisFigures?: never;
          missingSymbolsToLastUnavailableDate?: never;
          timer?: never;
      }
    | {
          error?: never;
          items: Array<ProcessedIndexItem>;
          itemSymbols: Array<string>;
          analysisFigures: Map<string, AnalysisFiguresType>;
          missingSymbolsToLastUnavailableDate: { [key: string]: string };
          timer: number;
      }
> => {
    // TODO: handle Rules Based
    const filteredStrategies = strategies.filter((strategy) => {
        const basicStrategy = asBasicStrategy(strategy);
        return basicStrategy === null || basicStrategy.securityIds.length > 0;
    });

    if (filteredStrategies.length === 0) return { error: 'No valid strategies present' };

    const variables = {
        backtestInputs: filteredStrategies.map((strategy) => {
            // loop through the strategies and build the back query
            const weightStrategy: WeightStrategyInput = BuildWeightStrategyInput(strategy);

            return {
                name: strategy.id.toString(),
                strategy: weightStrategy,
            };
        }),
        endDate: endDate === null ? FormatUtcDate(new Date(), 'yyyy-MM-dd') : endDate,
        instrumentIds: benchmarkInstrumentIds,
        publicIndexIds: benchmarkPublicIndexIds,
        startDate,
        thematicIndexIds: benchmarkThematicIndexIds,
    };

    const query = gql`
        mutation CreatePerformanceComparison(
            $startDate: Date!
            $endDate: Date!
            $backtestInputs: [BacktestInput!]!
            $publicIndexIds: [ID!]!
            $thematicIndexIds: [ID!]
            $instrumentIds: [ID!]
        ) {
            createPerformanceComparison(
                input: {
                    endDate: $endDate
                    startDate: $startDate
                    backtestInputs: $backtestInputs
                    publicIndexIds: $publicIndexIds
                    thematicIndexIds: $thematicIndexIds
                    instrumentIds: $instrumentIds
                }
            ) {
                results {
                    __typename
                    points {
                        asOfDate
                        value
                    }
                    analysis {
                        sharpeRatio
                        annualRollingVolatility
                        stdDev
                        drawdown
                        priceToEarnings
                        priceToSales
                        priceToBook
                    }
                    ... on PublicIndexResult {
                        publicIndex {
                            id
                            name
                            symbol
                        }
                    }
                    ... on ThematicIndexResult {
                        thematicIndex {
                            symbol
                        }
                    }
                    ... on InstrumentResult {
                        instrument {
                            symbol
                            exchangeName
                        }
                    }
                    ... on BacktestStrategyResult {
                        name
                        timer
                    }
                }
            }
        }
    `;

    const {
        createPerformanceComparison: { results },
    }: { createPerformanceComparison: { results: Array<ComparisonResult> } } = await GQL_CLIENT.request({
        document: query,
        variables,
        ...(abortController ? { signal: abortController.signal as AbortSignal } : {}),
    });

    // TODO (jopray): rather than having itemSymbols be an array of strings, use objects with symbol + type data (instrument or index)
    // in order to display them differently on the chart
    const itemSymbols: Array<string> = [];
    const analysisFigures = new Map<string, AnalysisFiguresType>();

    // construct map of asOfDate -> processedIndexItem starting with only the index backtest
    const dateToProcessedIndexItem: Map<string, ProcessedIndexItem> = new Map();
    const backtestStrategySymbols: Array<string> = [];
    const timers: Array<number> = [0.0];

    results.forEach((result) => {
        let symbol: string;
        let isPrimaryStrategy = false;
        switch (result.__typename) {
            case 'PublicIndexResult':
                symbol = result.publicIndex.symbol;
                break;
            case 'ThematicIndexResult':
                symbol = `${result.thematicIndex.symbol} (Published)`;
                break;
            case 'InstrumentResult':
                symbol = `${result.instrument.exchangeName}:${result.instrument.symbol}`;
                break;
            case 'BacktestStrategyResult': {
                const backtestStrategy = strategies.find((strategy) => strategy.id.toString() === result.name);
                if (!backtestStrategy) throw new Error(`Unexpected backtest result name ${result.name}`);
                symbol = backtestStrategy.name;
                backtestStrategySymbols.push(symbol);
                if (backtestStrategy.id === currentStrategyId) {
                    isPrimaryStrategy = true;
                }
                timers.push(result.timer);
                break;
            }
        }

        // validate here instead of as `default` because typescript recognizes the switch is already exhaustive
        if (!symbol) throw new Error(`Unexpected result type ${result.__typename}`);

        result.points.forEach((point: BacktestPointType) => {
            const { asOfDate, value: rawValue } = point;
            const value = parseFloat(rawValue);
            const entry = dateToProcessedIndexItem.get(asOfDate);
            if (entry) {
                entry.symbolValues[symbol] = value;
            } else {
                // First time seeing this date, add the structure
                dateToProcessedIndexItem.set(asOfDate, {
                    asOfDate,
                    symbolValues: {
                        [symbol]: value,
                    },
                });
            }
        });

        if (isPrimaryStrategy) {
            // primary to the front
            itemSymbols.unshift(symbol);
        } else {
            itemSymbols.push(symbol);
        }
        if (result.analysis) analysisFigures.set(symbol, result.analysis);
    });

    // for unknown legacy reasons, filter out dates that do *not* contain strategy symbol data
    // TODO: why do we need to do this? can we smooth the graph some other way?
    const filteredItems = Array.from(dateToProcessedIndexItem.values()).filter(({ symbolValues }) =>
        backtestStrategySymbols.every((symbol) => symbol in symbolValues)
    );

    const sortedItems: Array<ProcessedIndexItem> = [...filteredItems].sort((a, b) =>
        a.asOfDate < b.asOfDate ? -1 : 1
    );

    const { items, missingSymbolsToLastUnavailableDate } = filterItemsForCommonStartDate(sortedItems, itemSymbols);

    return {
        analysisFigures,
        itemSymbols,
        items,
        missingSymbolsToLastUnavailableDate,
        timer: Math.max(...timers),
    };
};

// TODO: optimize
const filterItemsForCommonStartDate = (
    sortedItems: Array<ProcessedIndexItem>,
    symbols: Array<string>
): { items: Array<ProcessedIndexItem>; missingSymbolsToLastUnavailableDate: { [key: string]: string } } => {
    const missingSymbolsToLastUnavailableDate: { [key: string]: string } = {};

    // no data returned if no items have all symbols
    const startIndex = sortedItems.findIndex((item: ProcessedIndexItem) => {
        const itemMissingSymbols = symbols.filter((symbol) => !(symbol in item.symbolValues));
        if (itemMissingSymbols.length === 0) {
            return true;
        }

        // Record the symbols for which we do not yet have data
        itemMissingSymbols.forEach((symbol) => (missingSymbolsToLastUnavailableDate[symbol] = item.asOfDate));

        // Continue the iteration
        return false;
    });

    return {
        items: sortedItems.slice(startIndex),
        missingSymbolsToLastUnavailableDate,
    };
};

export const TimeDelta = (months: number, fromDate: Date = new Date()) => {
    const start = fromDate.setMonth(fromDate.getMonth() - months);
    return FormatUtcDate(start, 'yyyy-MM-dd');
};

export const CreateIndex = async (
    organizationId: string,
    name: string,
    description: string,
    symbol: string,
    benchmarks: Array<string>,
    originatingAnalystThemeRequestId: string,
    isPortfolio: boolean,
    isHidden: boolean
) => {
    const variables = {
        benchmarks,
        description,
        isHidden,
        isPortfolio,
        name,
        organizationId,
        originatingAnalystThemeRequestId: originatingAnalystThemeRequestId || null,
        symbol: symbol || null,
    };

    const query = gql`
        mutation createIndex(
            $organizationId: ID!
            $name: String!
            $description: String!
            $symbol: String
            $benchmarks: [ID!]!
            $originatingAnalystThemeRequestId: ID
            $isPortfolio: Boolean!
            $isHidden: Boolean!
        ) {
            createIndex(
                input: {
                    organizationId: $organizationId
                    name: $name
                    description: $description
                    longDescription: $description
                    symbol: $symbol
                    benchmarkIds: $benchmarks
                    originatingAnalystThemeRequestId: $originatingAnalystThemeRequestId
                    isPortfolio: $isPortfolio
                    isHidden: $isHidden
                }
            ) {
                __typename
                ... on FieldErrors {
                    errors {
                        field
                        message
                    }
                }
                ... on Index {
                    id
                    name
                }
            }
        }
    `;

    const response: {
        createIndex:
            | {
                  __typename: 'FieldErrors';
                  errors: Array<{
                      field: string;
                      message: string;
                  }>;
              }
            | {
                  __typename: 'Index';
                  id: string;
                  name: string;
              };
    } = await GQL_CLIENT.request(query, variables);
    return response.createIndex;
};

export const CreateIndexVersionDraft = async (
    strategy: StrategyType,
    indexId: string,
    name: string,
    indexName: string,
    indexDescription: string,
    indexBenchmarkIndexIds: Array<string>,
    withQuarterlyRebalanceSchedule: boolean,
    indexIsPortfolio: boolean,
    indexIsHidden: boolean,
    andPublish: boolean
) => {
    const weightStrategy: WeightStrategyInput = BuildWeightStrategyInput(strategy);

    const variables = {
        andPublish,
        indexBenchmarkIndexIds,
        indexDescription,
        indexId,
        indexIsHidden,
        indexIsPortfolio,
        indexName,
        name: `${format(new Date(), 'yyyy-MM-dd')} version of ${name}`,
        weightStrategy,
        withQuarterlyRebalanceSchedule,
    };

    const query = gql`
        mutation createIndexVersion(
            $indexId: ID!
            $name: String!
            $weightStrategy: WeightStrategyInput!
            $indexName: String!
            $indexDescription: String!
            $indexBenchmarkIndexIds: [ID!]!
            $withQuarterlyRebalanceSchedule: Boolean
            $indexIsPortfolio: Boolean
            $indexIsHidden: Boolean
            $andPublish: Boolean
        ) {
            createIndexVersion(
                input: {
                    indexId: $indexId
                    name: $name
                    weightStrategy: $weightStrategy
                    indexName: $indexName
                    indexDescription: $indexDescription
                    indexBenchmarkIndexIds: $indexBenchmarkIndexIds
                    withQuarterlyRebalanceSchedule: $withQuarterlyRebalanceSchedule
                    indexIsPortfolio: $indexIsPortfolio
                    indexIsHidden: $indexIsHidden
                    andPublish: $andPublish
                }
            ) {
                __typename
                ... on FieldErrors {
                    errors {
                        field
                        message
                    }
                }
                ... on IndexVersion {
                    id
                }
            }
        }
    `;

    const response: {
        createIndexVersion:
            | {
                  __typename: 'FieldErrors';
                  errors: Array<{
                      field: string;
                      message: string;
                  }>;
              }
            | {
                  __typename: 'IndexVersion';
                  id: string;
              };
    } = await GQL_CLIENT.request(query, variables);
    return response.createIndexVersion;
};

export const SymbolExists = async (symbol: string) => {
    const variables = {
        symbol: symbol.toUpperCase(),
    };

    const query = gql`
        query GetIndex($symbol: String!) {
            index(symbol: $symbol) {
                id
            }
        }
    `;

    const response: {
        index: Optional<{
            id: string;
        }>;
    } = await GQL_CLIENT.request(query, variables);
    if (response.index) {
        return true;
    }

    return false;
};

const VALID_SYMBOL_REGEX = /^([A-Z]){4,8}$/;
export const SymbolValid = (symbol: string): boolean => {
    return VALID_SYMBOL_REGEX.test(symbol);
};

export type BenchmarkIndexType = {
    id: string;
    name: string;
    symbol: string;
};

export const QueryBenchmarks = (): Promise<{ benchmarkIndexes: Array<BenchmarkIndexType> }> => {
    const query = gql`
        query getBenchmarkOptions {
            benchmarkIndexes {
                id
                name
                symbol
            }
        }
    `;
    return GQL_CLIENT.request(query);
};
